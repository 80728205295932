import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@environments/environment';
import { AccountsFacade } from '@shared/accounts/store/accounts.facade';
import { BsmNodeItemComponent } from '@shared/business-structure/components/bsm-node-item/bsm-node-item.component';
import { BsmTreeItemComponent } from '@shared/business-structure/components/bsm-tree-item/bsm-tree-item.component';
import { BusinessStructureHelper } from '@shared/business-structure/helpers/business-structure.helper';
import { IBsTreeItem } from '@shared/business-structure/interfaces/bs-tree-item.interface';
import { IBsTreeMappingItem } from '@shared/business-structure/interfaces/bs-tree-mapping-item.interface';
import { BsNodeItem } from '@shared/business-structure/models/bs-node-item.model';
import { BsTreeItemNode } from '@shared/business-structure/models/bs-tree-item-node.model';
import { BsTreeItem } from '@shared/business-structure/models/bs-tree-item.model';
import { BusinessUnitPayload } from '@shared/business-structure/models/business-unit-payload.model';
import { BusinessUnitTag } from '@shared/business-structure/models/business-unit-tag.model';
import { BusinessUnitTagsPayload } from '@shared/business-structure/models/business-unit-tags-payload.model';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import { BusinessStructureService } from '@shared/business-structure/services/business-structure.service';
import { BusinessStructureFacade } from '@shared/business-structure/store/business-structure.facade';
import {
  BsDragItemProps,
  BsItemDragSelectedEvent,
  BsItemDragTargetEvent,
  BsItemTargetProps,
  BsViewDragProps,
  BsViewHistoryProps,
  BsViewMoveProps
} from '@shared/business-structure/types/bsm-view.types';
import { Client } from '@shared/clients/models/client.model';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';
import { ModalService } from '@shared/modals/services/modal.service';
import { NgxdropdownService } from '@shared/ngxdropdown/services/ngxdropdown.service';
import { PopupComponent } from '@shared/popups/components/popup.component';
import { instanceToInstance } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { combineLatestWith, filter, ReplaySubject } from 'rxjs';

const wheelEvent = (e: WheelEvent): void => {
  e.preventDefault();
};

const keyboardEvent = (e: KeyboardEvent): void => {
  if (e.ctrlKey && ['0', '-', '='].includes(e.key)) {
    e.preventDefault();
  }
};

@Component({
  selector: 'business-structure-manager',
  templateUrl: './business-structure-manager.component.html',
  styleUrls: ['./business-structure-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessStructureManagerComponent implements OnInit, AfterViewInit, OnDestroy {
  private __destroyRef = inject(DestroyRef);

  protected _isEditNode = false;
  protected _isEditTree = false;
  protected _isDetailsEnabled = false;
  protected _data: BsNodeItem[] = [];
  protected _treeRender: BsTreeItemNode[] = [];
  protected _treeStorage: { [treeId: string | number]: BsTreeItemNode[] } = {};
  protected _mapping$: ReplaySubject<{ mapping: IBsTreeMappingItem[]; items: BsTreeItemNode[] }> = new ReplaySubject(1);
  readonly mapping$ = this._mapping$.asObservable();
  public isChanged = false;
  public _tree: BsTreeItemNode[] = [];
  protected _levelNo = 1;
  protected _moveProps: BsViewMoveProps = {
    zoom: 1,
    x: 0,
    y: 0
  };
  protected _dragProps: BsViewDragProps = null;
  protected _dragNodeItem: BsmNodeItemComponent;
  protected _dragMappingNodeItem: BsmNodeItemComponent;
  protected _dragNodeItemProps: BsDragItemProps = {};
  protected _dragTreeItems: BsTreeItemNode[] = [];
  protected _dragTreeItemProps: BsDragItemProps = {};
  protected _historyProps: BsViewHistoryProps = {
    currentStep: 0,
    steps: []
  };
  private __noAnimations = false;
  private __nodeTreeTargets: BsItemTargetProps<BsmNodeItemComponent>[] = [];
  private __wheelTimer: any = null;
  protected _mode: 'manager' | 'mapping' = null;
  protected _treeAllSelected = false;
  protected _treeSomeSelected = false;
  public isDetailsOpen = false;
  private __isDragTreeOn = false;
  private __userBus: number[] = [];
  @Input() editEnabled = false;
  @Input() platform: string;
  @Input() treeItemsType: 'account';
  @Input() historySteps = 10;
  @Input() fitPadding = 50;
  @Input() zoomStep = 0.4;
  @Input() zoomMin = 0.35;
  @Input() zoomMax = 10;
  @Input() moveStep = 2;
  @Input() dragMatchPercent = 51;
  @Input() allowedLevelsDepth = environment.businessStructure.allowedLevelsDepth;
  @Output() structureChange = new EventEmitter<boolean>();
  @Output() dragTargetItem = new EventEmitter<BsItemDragTargetEvent<BsmNodeItemComponent | BsmTreeItemComponent>>();
  @Output() dragSelectedItem = new EventEmitter<BsItemDragSelectedEvent<BsmNodeItemComponent | BsTreeItemNode[]>>();
  @Output() authWarningClick = new EventEmitter<BsTreeItemNode>();
  @Output() getAccounts = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<IBsTreeItem>();
  @ViewChild('viewport') private __viewportRef: ElementRef;
  @ViewChild('viewscroll') private __viewscrollRef: ElementRef;
  @ViewChildren(BsmNodeItemComponent) private __bsmItems: QueryList<BsmNodeItemComponent>;
  @ViewChild('treeDragItems') private __treeDragItems: ElementRef;

  constructor(
    private readonly __popupService: ModalService,
    private readonly __cd: ChangeDetectorRef,
    private readonly __hostRef: ElementRef,
    private readonly __businessStructureFacade: BusinessStructureFacade,
    private readonly __businessStructureService: BusinessStructureService,
    private readonly __clientsFacade: ClientFacade,
    private readonly __loaderService: GlobalLoaderService,
    private readonly __ngxDropdownService: NgxdropdownService,
    private readonly __accountsFacade: AccountsFacade,
    private readonly __toastService: ToastrService
  ) {}

  get fitShouldZoomIn(): boolean {
    const portBox: DOMRect = this.__viewportRef.nativeElement.getBoundingClientRect();
    const scrollBox: DOMRect = this.__viewscrollRef.nativeElement.getBoundingClientRect();

    return scrollBox.width < portBox.width && scrollBox.height < portBox.height;
  }

  get fitShouldZoomOut(): boolean {
    const portBox: DOMRect = this.__viewportRef.nativeElement.getBoundingClientRect();
    const scrollBox: DOMRect = this.__viewscrollRef.nativeElement.getBoundingClientRect();

    return scrollBox.width > portBox.width || scrollBox.height > portBox.height;
  }

  get isEdit(): boolean {
    return this._isEditNode || this._isEditTree;
  }

  get hasDragItem(): boolean {
    return !!(this._dragNodeItem || this.hasTreeDragItem);
  }

  get hasTreeDragItem(): boolean {
    return !!(this._dragTreeItems.length && this._dragTreeItemProps.portBox && this.__isDragTreeOn);
  }

  get mode(): 'manager' | 'mapping' | '' {
    return this._mode;
  }

  get selectedClient(): Client {
    return this.__clientsFacade.selectedClient;
  }

  get userBus(): number[] {
    return this.__userBus;
  }

  @HostBinding('class')
  get hostCssClass(): string {
    const cls: string[] = [];

    if (this._isEditNode) {
      cls.push(`is-edit`);
    }

    if (this.isChanged) {
      cls.push(`is-changed`);
    }

    if (this._dragProps && this._dragProps.x !== null) {
      cls.push('is-drag');
    }

    if (this.__noAnimations) {
      cls.push('no-animations');
    }

    if (this._mode === 'mapping') {
      cls.push('is-mapping');
    }

    return cls.join(' ');
  }

  @HostBinding('style')
  get hostCssStyle(): Record<string, any> {
    const style: any = {
      '--bsm-zoom': this._moveProps.zoom,
      '--bsm-move-x': `${this._moveProps.x}px`,
      '--bsm-move-y': `${this._moveProps.y}px`
    };

    if (this._dragNodeItem && this._dragNodeItemProps) {
      style['--bsm-item-drag-x'] = `${this._dragNodeItemProps.left}px`;
      style['--bsm-item-drag-y'] = `${this._dragNodeItemProps.top}px`;
    } else if (this._dragMappingNodeItem && this._dragNodeItemProps) {
      style['--bsm-item-drag-x'] = `${this._dragNodeItemProps.left}px`;
      style['--bsm-item-drag-y'] = `${this._dragNodeItemProps.top}px`;
    } else if (this._dragTreeItems.length && this._dragTreeItemProps) {
      style['--bsm-item-drag-x'] = `${this._dragTreeItemProps.left}px`;
      style['--bsm-item-drag-y'] = `${this._dragTreeItemProps.top}px`;
    }

    return style;
  }

  @Input()
  set mapping(mapping: IBsTreeMappingItem[]) {
    this._mapping$.next({ mapping, items: this._tree });
  }

  @Input()
  set treeItems(value: IBsTreeItem[]) {
    const tree = this.__treeMap(value).filter(
      (item) => !item.item.is_mapped_to_current_client || item.item.is_mapped_to_unaccessible_bu || item.item.is_connected
    );
    const { available, render, storage } = this.__treeHierarchy(tree);
    this._tree = available;
    this._treeRender = render;
    this._treeStorage = storage;
    this.__cd.detectChanges();
  }

  @Input()
  set mode(value: 'manager' | 'mapping' | '') {
    if (!this._mode) {
      this._mode = !value ? 'mapping' : value;
    }
  }

  @HostListener('setDragTreeItems', ['$event'])
  onCustomEventCaptured(): void {
    this.__setDragTreeItems();
  }

  @HostListener('document:keydown', ['$event'])
  protected _onDocumentKeydown(e: KeyboardEvent): void {
    if (e.ctrlKey) {
      switch (e.key) {
        case '0':
          this.fitToView();
          break;
        case '-':
          this.zoomOut(100);
          break;
        case '=':
          this.zoomIn(100);
          break;
        case 'z':
          this.historyUndo();
          break;
        case 'y':
          this.historyRedo();
          break;
        case 'ArrowUp':
          this.moveDown();
          break;
        case 'ArrowDown':
          this.moveUp();
          break;
        case 'ArrowLeft':
          this.moveRight();
          break;
        case 'ArrowRight':
          this.moveLeft();
          break;
      }
    }
  }

  @HostListener('wheel', ['$event'])
  protected _onHostWheel(e: WheelEvent): void {
    if (this.hasDragItem) {
      // No viewscroll manipulation if tree item is being dragged
      return;
    }

    if (e.target !== this.__viewportRef.nativeElement && !this.__viewportRef.nativeElement.contains(e.target)) {
      return;
    }

    const timerClearDuration = 100;

    if (e.ctrlKey) {
      const delta = Math.abs(e.deltaY);

      if (!this.__wheelTimer) {
        if (e.deltaY < 0) {
          this.zoomIn(delta);
        } else {
          this.zoomOut(delta);
        }

        this.__wheelTimer = setTimeout(() => {
          clearTimeout(this.__wheelTimer);
          this.__wheelTimer = null;
        }, timerClearDuration);
      }
    } else if (e.shiftKey) {
      const delta = Math.abs(e.deltaY);

      if (!this.__wheelTimer) {
        if (e.deltaY < 0) {
          this.moveRight(delta);
        } else {
          this.moveLeft(delta);
        }

        this.__wheelTimer = setTimeout(() => {
          clearTimeout(this.__wheelTimer);
          this.__wheelTimer = null;
        }, timerClearDuration);
      }
    } else {
      const delta = Math.abs(e.deltaY);

      if (!this.__wheelTimer) {
        if (e.deltaY < 0) {
          this.moveDown(delta);
        } else {
          this.moveUp(delta);
        }

        this.__wheelTimer = setTimeout(() => {
          clearTimeout(this.__wheelTimer);
          this.__wheelTimer = null;
        }, timerClearDuration);
      }
    }

    if (this._dragProps) {
      this._dragProps.lastX = this._moveProps.x;
      this._dragProps.lastY = this._moveProps.y;
    }
  }

  @HostListener('document:mousedown', ['$event'])
  protected _onDocumentMousedown(e: MouseEvent): void {
    let target: HTMLElement;

    if (this._dragProps && this._dragProps.x !== null) {
      return;
    }

    if (e.target === this.__viewportRef.nativeElement || this.__viewportRef.nativeElement.contains(e.target)) {
      target = e.target as HTMLElement;

      if (!target.classList.contains('no-drag') && !target.closest('.no-drag')) {
        // Viewscroll drag
        this._dragProps = {
          lastX: this._dragProps?.lastX || 0,
          lastY: this._dragProps?.lastY || 0,
          x: e.clientX,
          y: e.clientY
        };
      }
    }
  }

  @HostListener('document:mousemove', ['$event'])
  protected _onDocumentMousemove(e: MouseEvent): void {
    if (this._dragNodeItem) {
      // Dragging node item
      this._dragNodeItemProps.left = e.clientX - (this._dragNodeItemProps.xDiff || 0);
      this._dragNodeItemProps.top = e.clientY - (this._dragNodeItemProps.yDiff || 0);

      this.__findTargetItemForNodeItem();
    } else if (this._dragMappingNodeItem) {
      // Dragging node item items
      this._dragNodeItemProps.left = e.clientX - (this._dragNodeItemProps.xDiff || 0);
      this._dragNodeItemProps.top = e.clientY - (this._dragNodeItemProps.yDiff || 0);

      this.__findTargetItemForMappingNodeItem();
    } else if (this.__isDragTreeOn) {
      // Dragging tree item
      this._dragTreeItemProps.left = e.clientX - (this._dragTreeItemProps.xDiff || 0);
      this._dragTreeItemProps.top = e.clientY - (this._dragTreeItemProps.yDiff || 0);

      this.__findTargetItemForTreeItem();
    } else if (this._dragProps && this._dragProps.x !== null) {
      // Moving viewscroll
      this._moveProps.x = (this._dragProps.lastX || 0) + (e.clientX - this._dragProps.x) / this._moveProps.zoom;
      this._moveProps.y = (this._dragProps.lastY || 0) + (e.clientY - this._dragProps.y) / this._moveProps.zoom;
    }
  }

  @HostListener('document:mouseup', ['$event'])
  protected _onDocumentMouseup(e: MouseEvent): void {
    if (this._dragProps) {
      this._dragProps.lastX = this._moveProps.x;
      this._dragProps.lastY = this._moveProps.y;
      this._dragProps.x = null;
      this._dragProps.y = null;

      this.__refreshTargets();
    }
  }

  ngOnInit(): void {
    if (!this._mode) {
      this._mode = 'mapping';
    }

    this.__clientsFacade.client$.pipe(combineLatestWith(this.__businessStructureFacade.businessStructure$)).subscribe(() => {
      this.__noAnimations = true;
      const structure = BusinessStructureHelper.extractAccessibleTree(instanceToInstance(this.__businessStructureFacade.currentTree));
      this.__userBus = BusinessStructureHelper.getAccessibleBusinessUnits(structure);
      this._data = this.__parseBusinessUnits(structure, this.__clientsFacade.selectedClient);
      this.__historyStart();

      this.__cd.markForCheck();

      setTimeout(() => {
        if (this.fitShouldZoomOut) {
          this.fitToView();
          this.__cd.markForCheck();

          setTimeout(() => {
            this.__noAnimations = false;
            this.__cd.markForCheck();
          }, 50);
        } else {
          this.__noAnimations = false;
          this.__cd.markForCheck();
        }
      }, 0);
    });
  }

  ngAfterViewInit(): void {
    this.__bsmItems.changes
      .pipe(takeUntilDestroyed(this.__destroyRef))
      .subscribe((items: QueryList<BsmNodeItemComponent>) => this.__refreshTargets());

    this.__viewportRef.nativeElement.addEventListener('wheel', wheelEvent, { passive: false });
    window.addEventListener('keydown', keyboardEvent, { passive: false });

    this.__clientsFacade.selectedClient$
      .pipe(
        takeUntilDestroyed(this.__destroyRef),
        filter((client) => client?.is_active === undefined)
      )
      .subscribe(() => {
        this.reload();
      });

    this.__refreshTargets();
  }

  ngOnDestroy(): void {
    this.__viewportRef.nativeElement.removeEventListener('wheel', wheelEvent);
    window.removeEventListener('keydown', keyboardEvent);
  }

  private __parseBusinessUnits(divisions: BusinessUnit[], client?: Client): BsNodeItem[] {
    if (client) {
      const children: BsNodeItem[] = this.__parseBusinessUnits(divisions);

      const rootNode = new BsNodeItem();
      rootNode.id = 0;
      rootNode.name = client.name;
      rootNode.children = children;
      rootNode.isRoot = true;

      return [rootNode];
    } else {
      const childred: BsNodeItem[] = [];

      for (const division of divisions) {
        const node = new BsNodeItem();
        node.id = division.id;
        node.name = division.name;
        node.division = division;
        node.children = this.__parseBusinessUnits(division.divisions || []);

        childred.push(node);
      }

      return childred;
    }
  }

  searchTag(query: string, division: BusinessUnit): BusinessUnitTag[] {
    return this.__searchForTags(query, this._data, division).filter(
      (tag: BusinessUnitTag, index: number, tags: BusinessUnitTag[]) => tags.indexOf(tag) === index
    );
  }

  private __searchForTags(query: string, data: BsNodeItem[], division: BusinessUnit): BusinessUnitTag[] {
    const regExp = new RegExp(query, 'gi');
    const tags: BusinessUnitTag[] = [];

    for (const item of data) {
      if ((!division || division !== item.division) && item.division?.tags && item.division?.tags.length) {
        tags.push(...item.division.tags.filter((tag) => tag.name.match(regExp)));
      }

      if (item.children && item.children.length) {
        tags.push(...this.__searchForTags(query, item.children, division));
      }
    }

    return tags;
  }

  private __findTargetItemForNodeItem(): void {
    const item: BsItemTargetProps<BsmNodeItemComponent> = {
      ...this._dragNodeItemProps,
      item: this._dragNodeItem,
      right: this._dragNodeItemProps.portBox.left + this._dragNodeItemProps.left + this._dragNodeItemProps.width,
      bottom: this._dragNodeItemProps.portBox.top + this._dragNodeItemProps.top + this._dragNodeItemProps.height
    };
    item.left += this._dragNodeItemProps.portBox.left;
    item.top += this._dragNodeItemProps.portBox.top;

    for (const target of this.__nodeTreeTargets) {
      if (target.item === this._dragNodeItem) {
        continue;
      }

      target.isTarget =
        this.__isIntersecting(item, target) > this.dragMatchPercent &&
        !item.item.hasChild(target.item) &&
        !target.item.isParent(item.item) &&
        !target.item.isMaxDepthAllowed &&
        target.item.hasAvailableDepth(item.item);

      this.dragTargetItem.emit({ source: this._dragNodeItem, isTarget: target.isTarget, target: target.item });
    }
  }

  private __findTargetItemForMappingNodeItem(): void {
    const item: BsItemTargetProps<BsmNodeItemComponent> = {
      ...this._dragNodeItemProps,
      item: this._dragMappingNodeItem,
      right: this._dragNodeItemProps.portBox.left + this._dragNodeItemProps.left + this._dragNodeItemProps.width,
      bottom: this._dragNodeItemProps.portBox.top + this._dragNodeItemProps.top + this._dragNodeItemProps.height
    };
    item.left += this._dragNodeItemProps.portBox.left;
    item.top += this._dragNodeItemProps.portBox.top;

    for (const target of this.__nodeTreeTargets) {
      target.intersection = this.__isIntersecting(item, target);
    }
    const maxIntersect = Math.max(...this.__nodeTreeTargets.map((t) => t.intersection));
    const targetIndex = this.__nodeTreeTargets.findIndex((t) => t.intersection === maxIntersect && t.intersection > 1);
    this.__nodeTreeTargets.forEach((target, i) => {
      target.isTarget = i === targetIndex;
      this.dragTargetItem.emit({ source: this._dragMappingNodeItem, isTarget: target.isTarget, target: target.item });
    });
  }

  private __findTargetItemForTreeItem(): void {
    if (!this._dragTreeItemProps.portBox) {
      return;
    }

    const item: BsItemTargetProps<BsmTreeItemComponent> = {
      ...this._dragTreeItemProps,
      item: null,
      right: this._dragTreeItemProps.portBox.left + this._dragTreeItemProps.left + this._dragTreeItemProps.width,
      bottom: this._dragTreeItemProps.portBox.top + this._dragTreeItemProps.top + this._dragTreeItemProps.height
    };
    item.left += this._dragTreeItemProps.portBox.left;
    item.top += this._dragTreeItemProps.portBox.top;

    for (const target of this.__nodeTreeTargets) {
      target.intersection = this.__isIntersecting(item, target);
    }
    const maxIntersect = Math.max(...this.__nodeTreeTargets.map((t) => t.intersection));
    const targetIndex = this.__nodeTreeTargets.findIndex((t) => t.intersection === maxIntersect && t.intersection > 1);
    this.__nodeTreeTargets.forEach((target, i) => {
      target.isTarget = i === targetIndex;
      this.dragTargetItem.emit({ source: null, isTarget: target.isTarget, target: target.item });
    });
  }

  private __isIntersecting(
    item: BsItemTargetProps<BsmNodeItemComponent | BsmTreeItemComponent>,
    target: BsItemTargetProps<BsmNodeItemComponent>
  ): number {
    const xOverlap = Math.max(0, Math.min(item.right, target.right) - Math.max(item.left, target.left));
    const yOverlap = Math.max(0, Math.min(item.bottom, target.bottom) - Math.max(item.top, target.top));
    const targetArea = (target.right - target.left) * (target.bottom - target.top);
    const overlapArea = xOverlap * yOverlap;
    const overlapPercent = (overlapArea * 100) / targetArea;

    return overlapPercent;
  }

  private __setDragTreeItems(): void {
    this._dragTreeItems = this._tree.filter(
      (i) => i.item.item.selected && !i.item.item.is_mapped_to_other_client && !i.item.item.item.has_children
    );
  }

  dragTreeStart(data: { e: MouseEvent; bsmTreeItem: BsmTreeItemComponent }): void {
    this.__isDragTreeOn = true;
    setTimeout(() => {
      const portBox: DOMRect = this.__hostRef.nativeElement.getBoundingClientRect();
      const itemBox: DOMRect = data.bsmTreeItem.innerNativeElement.getBoundingClientRect();
      const treeDragItemsBox: DOMRect = this.__treeDragItems.nativeElement.getBoundingClientRect();
      this._dragTreeItemProps.xDiff = portBox.left + (data.e.clientX - itemBox.left);
      this._dragTreeItemProps.yDiff = portBox.top + (data.e.clientY - itemBox.top);
      this._dragTreeItemProps.portBox = portBox;
      this._dragTreeItemProps.width = treeDragItemsBox.right - treeDragItemsBox.left;
      this._dragTreeItemProps.height = treeDragItemsBox.bottom - treeDragItemsBox.top;

      /*
        A tiny HACK because this:
          this.__treeDragItems.nativeElement.getBoundingClientRect()
        was returning 0 values so it was messing up dragging hover detection logic.
      */
      if (
        this.__treeDragItems.nativeElement.getBoundingClientRect().width === 0 &&
        this.__treeDragItems.nativeElement.getBoundingClientRect().height === 0
      ) {
        this.dragTreeStart(data);
      }
    });
  }

  dragTreeError(data: { e: MouseEvent; bsmTreeItem: BsmTreeItemComponent }): void {
    this.__isDragTreeOn = false;

    this.__popupService.showError({
      hasBackdrop: true,
      context: {
        title: `Account(s) not selected`,
        message: `Select the account(s) before dragging.`
      }
    });
  }

  dragTreeStop(bsmTreeItem: BsmTreeItemComponent): void {
    this.__isDragTreeOn = false;

    const targetNodes: BsmNodeItemComponent[] = [];

    for (const target of this.__nodeTreeTargets) {
      if (target.isTarget) {
        targetNodes.push(target.item);
      } else {
        this.dragTargetItem.emit({ source: this._dragNodeItem, isTarget: false, target: target.item });
      }
    }

    if (targetNodes.length) {
      const dragItems = [...this._dragTreeItems];

      const nodesWithMappeItems = this.__nodeTreeTargets.filter(
        (item) =>
          !targetNodes.includes(item.item) &&
          item.item.item.accounts &&
          item.item.item.accounts.some((account) => dragItems.some((dragItem) => dragItem.item.item.id === account.id))
      );

      if (nodesWithMappeItems.length && this.treeItemsType === 'account') {
        this.__popupService.showConfirm({
          hasBackdrop: true,
          context: {
            message:
              'You are about to split an Account Asset bundle or move it to another business unit.<br /><br />Are you sure want to complete this action?',
            conformText: 'Split',
            confirmCb: (popup: PopupComponent) => {
              popup.hide();

              for (const node of nodesWithMappeItems) {
                if (this.treeItemsType === 'account') {
                  node.item.item.accounts = node.item.item.accounts
                    ? node.item.item.accounts.filter((account) => !dragItems.some((item) => item.item.item.id === account.id))
                    : [];
                }
              }

              for (const target of targetNodes) {
                this.dragSelectedItem.emit({ dragged: dragItems, target });
              }

              this.__refreshTargets();
              this.__cd.detectChanges();
            }
          }
        });

        return;
      } else {
        for (const target of targetNodes) {
          this.dragSelectedItem.emit({ dragged: dragItems, target });
        }
      }

      this.__refreshTargets();
      this.__cd.detectChanges();
    }
  }

  protected _dragItemStart(data: { e: MouseEvent; bsmNodeItem: BsmNodeItemComponent }): void {
    const portBox: DOMRect = this.__viewportRef.nativeElement.getBoundingClientRect();
    let itemBox: DOMRect;

    if (this.treeItemsType === 'account') {
      itemBox = data.bsmNodeItem.draggedAccountElement.getBoundingClientRect();
    }

    this._dragNodeItemProps.xDiff = portBox.left + (data.e.clientX - itemBox.left);
    this._dragNodeItemProps.yDiff = portBox.top + (data.e.clientY - itemBox.top);
    this._dragNodeItemProps.portBox = portBox;
    this._dragNodeItemProps.width = itemBox.right - itemBox.left;
    this._dragNodeItemProps.height = itemBox.bottom - itemBox.top;

    this._dragMappingNodeItem = data.bsmNodeItem;
  }

  protected _dragItemStop(bsmNodeItem: BsmNodeItemComponent): void {
    if (!this._dragMappingNodeItem) {
      return;
    }

    let hasTarget = false;
    let thisTarget: BsItemTargetProps<BsmNodeItemComponent>;
    let accounts = this._dragMappingNodeItem.draggedAccounts;
    for (const target of this.__nodeTreeTargets) {
      if (target.isTarget) {
        hasTarget = true;
        thisTarget = target;
        this._dragMappingNodeItem.item.accounts = [];

        this.dragSelectedItem.emit({
          dragged: this._dragMappingNodeItem,
          target: target.item,
          accounts: this.treeItemsType === 'account' ? accounts : null
        });

        break;
      } else {
        this.dragTargetItem.emit({ source: bsmNodeItem, isTarget: false, target: target.item });
      }
    }

    this.__nodeTreeTargets = this.__nodeTreeTargets.map((i) => {
      i.isTarget = false;
      return i;
    });

    if (!hasTarget && !thisTarget?.item) {
      this._dragMappingNodeItem = null;

      this.__cd.detectChanges();

      this.__popupService.showConfirm({
        context: {
          title: 'Warning!',
          message:
            'If you drop these accounts outside of the tree they will no longer be linked to your business.<br /><br />Are you sure you want to complete this action?',
          confirmCb: (popup: PopupComponent) => {
            popup.hide();

            if (accounts.length > 400) {
              accounts = accounts.slice(0, 400);
              this.__toastService.warning('Number of accounts too large, only 400 accounts will be unmapped');
            }
            this.__loaderService.create('edit-account');
            this.__accountsFacade.mappingDelete(
              this.__clientsFacade.selectedClient,
              accounts.map((account) => account.item)
            );

            accounts = [];
            this.__cd.detectChanges();

            this.__refreshTargets();

            this._hasChanged(true);
          }
        }
      });
    } else {
      this._dragMappingNodeItem = null;
      this.__cd.detectChanges();

      this.__refreshTargets();
    }
  }

  protected _dragNodeStart(data: { e: MouseEvent; bsmNodeItem: BsmNodeItemComponent }): void {
    if (!this._isEditNode) {
      return;
    }

    const portBox: DOMRect = this.__viewportRef.nativeElement.getBoundingClientRect();
    const itemBox: DOMRect = data.bsmNodeItem.innerNativeElement.getBoundingClientRect();

    this._dragNodeItemProps.xDiff = portBox.left + (data.e.clientX - itemBox.left);
    this._dragNodeItemProps.yDiff = portBox.top + (data.e.clientY - itemBox.top);
    this._dragNodeItemProps.portBox = portBox;
    this._dragNodeItemProps.width = itemBox.right - itemBox.left;
    this._dragNodeItemProps.height = itemBox.bottom - itemBox.top;

    this._dragNodeItem = data.bsmNodeItem;
  }

  protected _dragNodeStop(bsmNodeItem: BsmNodeItemComponent): void {
    if (!this._isEditNode) {
      return;
    }

    for (const target of this.__nodeTreeTargets) {
      if (target.item === this._dragNodeItem) {
        continue;
      }

      if (target.isTarget) {
        this.dragSelectedItem.emit({ dragged: this._dragNodeItem, target: target.item });
      } else {
        this.dragTargetItem.emit({ source: bsmNodeItem, isTarget: false, target: target.item });
      }
    }

    this._dragNodeItem = null;
    this.__cd.detectChanges();
  }

  private __historyStart(): void {
    const origin = instanceToInstance(this._data);
    this._historyProps.currentStep = 0;
    this._historyProps.origin = origin;
    this._historyProps.steps = [origin];
    this._hasChanged(false);
  }

  private __historyAdd(): void {
    const state = instanceToInstance(this._data);
    if (this._historyProps.currentStep < this._historyProps.steps.length - 1) {
      this._historyProps.steps.splice(this._historyProps.currentStep + 1, Infinity, state);
    } else {
      this._historyProps.steps = instanceToInstance([
        ...this._historyProps.steps.splice((this.historySteps - 1) * -1, this.historySteps - 1),
        state
      ]);
    }
    this._historyProps.currentStep = this._historyProps.steps.length - 1;
  }

  startEdit(): void {
    this._editChanged(true);
  }

  protected _editChanged(value: boolean): void {
    if (this._mode === 'manager') {
      this._isEditNode = value;

      if (!this._isEditNode) {
        this._historyProps.steps = [];
      }
    } else {
      this._treeMarkSelected(false);
      this._isEditTree = value;
    }
  }

  protected _hasChanged(value: boolean): void {
    this.isChanged = value;
    this.structureChange.emit(value);

    if (value && this._mode === 'manager') {
      this.__historyAdd();
    }
  }

  protected _resetAfterBusinessStructureSave(): void {
    this._historyProps.steps = [];
    this.isChanged = false;
  }

  protected _authWarningClick(node: BsTreeItemNode): void {
    this.authWarningClick.emit(node);
  }

  private __refreshTargets(): void {
    setTimeout(() => {
      const props: BsItemTargetProps<BsmNodeItemComponent>[] = [];

      for (const item of this.__bsmItems) {
        if (item.isPlaceholder) {
          continue;
        }

        const { left, top, right, bottom } = item.innerNativeElement.getBoundingClientRect();

        props.push({ item, left, top, right, bottom });
      }

      this.__nodeTreeTargets = props;
    }, 100);
  }

  cancelEdit(): void {
    if (!this.isChanged) {
      this._editChanged(false);
    } else {
      this.__popupService.showConfirm({
        context: {
          title: 'Reset view?',
          message: 'Are you sure to reset business structure tree? All changes will be lost.',
          confirmCb: (popup: PopupComponent) => {
            popup.hide();

            this._historyProps.currentStep = 0;
            this._historyProps.steps = [this._historyProps.origin];

            this._data = instanceToInstance(this._historyProps.origin);

            this._editChanged(false);
            this._hasChanged(false);

            this.reload();
          }
        }
      });
    }
  }

  private __extractSavePayload(nodes: BsNodeItem[], parent?: BusinessUnit): BusinessUnitPayload[] {
    const data: BusinessUnitPayload[] = [];

    for (const node of nodes) {
      if (parent) {
        const dto: BusinessUnitPayload = {
          name: node.division?.name || null,
          divisions: this.__extractSavePayload(node.children || [], node.division)
        };

        if (node.division?.id) {
          dto.id = node.division?.id;
        }

        if (node.division?.tags) {
          dto.tags = node.division.tags.map((tag) => {
            const dtoTags: BusinessUnitTagsPayload = {
              name: tag.name,
              color: tag.color
            };

            if (tag.id) {
              dtoTags.id = tag.id;
            }

            return dtoTags;
          });
        }

        data.push(dto);
      } else {
        for (const subNode of node.children) {
          const dto: BusinessUnitPayload = {
            name: subNode.division?.name || null,
            divisions: this.__extractSavePayload(subNode.children || [], subNode.division)
          };

          if (subNode.division?.id) {
            dto.id = subNode.division?.id;
          }

          if (subNode.division?.tags) {
            dto.tags = subNode.division.tags.map((tag) => {
              const dtoTags: BusinessUnitTagsPayload = {
                name: tag.name,
                color: tag.color
              };

              if (tag.id) {
                dtoTags.id = tag.id;
              }

              return dtoTags;
            });
          }

          data.push(dto);
        }
      }
    }

    return data;
  }

  private __findNode(nodeId: number, lookupNodes: BsNodeItem[]): BsNodeItem {
    let foundNode;
    if (nodeId === null || nodeId === undefined || !lookupNodes) {
      return foundNode;
    }
    for (const node of lookupNodes) {
      if (node.id === nodeId) {
        return node;
      }
      if (node.children) {
        foundNode = this.__findNode(nodeId, node.children);
      }

      if (foundNode) {
        return foundNode;
      }
    }
    return foundNode;
  }

  private __updateWholeTree(nodes: BsNodeItem[], updatedNodes: BsNodeItem[], parent?: BsNodeItem): void {
    if (!nodes || !updatedNodes) {
      return;
    }
    for (const node of nodes) {
      if (node?.division?.is_active && node.id !== undefined && node.id !== null) {
        const updatedNode = this.__findNode(node.id, updatedNodes);
        if (updatedNode) {
          if (updatedNode.division.parent_business_unit_id !== parent?.id && !parent?.isRoot) {
            const prevParent = this.__findNode(updatedNode.division.parent_business_unit_id, nodes);
            const nodeToDeleteIndex = prevParent ? prevParent.division.divisions.findIndex((child) => child.id === updatedNode.id) : -1;
            nodeToDeleteIndex !== -1 && prevParent.division.divisions.splice(nodeToDeleteIndex, 1);
          }
          Object.assign(node, updatedNode);
        } else if (parent) {
          parent.children = parent.children.filter((child) => child.id !== node.id);
        }
      }
      node?.children?.length && this.__updateWholeTree(node.children, updatedNodes, node);
    }
  }

  private __mapAddedFirstLevelNodes(updatedNodes: BsNodeItem[], nodes: BsNodeItem[], originalParent?: BsNodeItem): void {
    if (!nodes || !updatedNodes) {
      return;
    }

    for (const node of updatedNodes) {
      if (!node.isRoot && ((node.division.is_active && !node.id && originalParent) || node?.division?.parent_business_unit_id !== null)) {
        originalParent.children.push(node);
      }

      if (node.isRoot) {
        this.__mapAddedFirstLevelNodes(node.children, nodes, this.__findNode(node.id, nodes));
      }
    }
  }

  save(): void {
    if (this._mode === 'manager') {
      let treeFromRoot = this.__parseBusinessUnits(this.__businessStructureFacade.currentTree);
      let extractionData = this._data;
      if (this._data[0]?.children?.length || !this._data[0]?.division?.is_active) {
        treeFromRoot = [{ ...this._data[0], children: treeFromRoot } as BsNodeItem];
        this.__updateWholeTree(treeFromRoot, this._data);
        this.__mapAddedFirstLevelNodes(this._data, treeFromRoot);
        extractionData = treeFromRoot;
      }
      const payload = this.__extractSavePayload(extractionData);

      this.__loaderService.create('update-bs');

      this.__businessStructureService.saveBusinessStructureV2(payload).subscribe({
        next: (resp: { tree: BusinessUnit[]; flattenedDivisions: BusinessUnit[] }) => {
          this.__loaderService.dismiss('update-bs');

          this.__toastService.success('Business structure saved');

          this._resetAfterBusinessStructureSave();
          this.__businessStructureFacade.updateBusinessStructure(resp);
          this.__businessStructureFacade.getBusinessStructureSelection(this.__clientsFacade.selectedClient);
        },
        error: (error) => {
          this.__loaderService.dismiss('update-bs');
          this.__toastService.error(error.error.message || 'Something went wrong while saving business structure');
        }
      });
    }
  }

  reload(): void {
    if (!this.__clientsFacade.selectedClient) {
      return;
    }
    this.__ngxDropdownService.hideAll();
  }

  resetView(): void {
    this._moveProps.zoom = 1;
    this._moveProps.x = 0;
    this._moveProps.y = 0;

    if (!this._dragProps) {
      this._dragProps = {
        x: null,
        y: null
      };
    }

    this._dragProps.lastX = 0;
    this._dragProps.lastY = 0;

    this.__ngxDropdownService.hideAll();

    setTimeout(() => {
      this.__refreshTargets();
      this.__cd.detectChanges();
    }, 210);
  }

  fitToContent(): void {
    const portBox: DOMRect = this.__viewportRef.nativeElement.getBoundingClientRect();
    const scrollBox: DOMRect = this.__viewscrollRef.nativeElement.getBoundingClientRect();

    let newZoom: number;
    const newZoomY = ((portBox.height - this.fitPadding) * this._moveProps.zoom) / scrollBox.height;
    const newZoomX = ((portBox.width - this.fitPadding) * this._moveProps.zoom) / scrollBox.width;

    if (scrollBox.height >= scrollBox.width) {
      newZoom = newZoomY;
    } else {
      newZoom = newZoomX;
    }

    const y = ((scrollBox.height / 2) * newZoomY) / this._moveProps.zoom - portBox.height / 2 + this.fitPadding / 2;
    const x = ((scrollBox.width / 2) * newZoomX) / this._moveProps.zoom - portBox.width / 2 + this.fitPadding / 2;

    newZoom = newZoom < this.zoomMin ? this.zoomMin : newZoom;

    this._moveProps.zoom = newZoom;
    this._moveProps.x = x;
    this._moveProps.y = y + 50; //needed to add +50 so that Global level is not hidden behind explanatin box on initial load

    if (this._dragProps) {
      this._dragProps.lastX = this._moveProps.x;
      this._dragProps.lastY = this._moveProps.y;
      this._dragProps.x = null;
      this._dragProps.y = null;
    }

    this.__ngxDropdownService.hideAll();

    setTimeout(() => {
      this.__refreshTargets();
      this.__cd.detectChanges();
    }, 210);
  }

  fitToView(): void {
    const portBox: DOMRect = this.__viewportRef.nativeElement.getBoundingClientRect();
    const scrollBox: DOMRect = this.__viewscrollRef.nativeElement.getBoundingClientRect();

    const scrollBoxBase = {
      width: Math.round(scrollBox.width / this._moveProps.zoom),
      height: Math.round(scrollBox.height / this._moveProps.zoom)
    };

    if (scrollBoxBase.width < portBox.width && scrollBoxBase.height < portBox.height) {
      this.resetView();
    } else {
      this.fitToContent();
    }

    this.__ngxDropdownService.hideAll();

    setTimeout(() => {
      this.__refreshTargets();
      this.__cd.detectChanges();
    }, 210);
  }

  zoomIn(modifier = 1): void {
    this._moveProps.zoom += (modifier / (this.zoomStep * 400)) * this._moveProps.zoom;
    if (this._moveProps.zoom > this.zoomMax) {
      this._moveProps.zoom = this.zoomMax;
    }

    this.__ngxDropdownService.hideAll();

    setTimeout(() => {
      this.__refreshTargets();
      this.__cd.detectChanges();
    }, 210);
  }

  zoomOut(modifier = 1): void {
    this._moveProps.zoom -= (modifier / (this.zoomStep * 400)) * this._moveProps.zoom;
    if (this._moveProps.zoom < this.zoomMin) {
      this._moveProps.zoom = this.zoomMin;
    }

    this.__ngxDropdownService.hideAll();

    setTimeout(() => {
      this.__refreshTargets();
      this.__cd.detectChanges();
    }, 210);
  }

  moveDown(modifier = 1): void {
    this._moveProps.y += (modifier * this.moveStep) / this._moveProps.zoom;
    if (!this._dragProps) {
      this._dragProps = {
        lastX: 0,
        x: null,
        y: null
      };
    }
    this._dragProps.lastY = this._moveProps.y;

    this.__ngxDropdownService.hideAll();
  }

  moveUp(modifier = 1): void {
    this._moveProps.y -= (modifier * this.moveStep) / this._moveProps.zoom;
    if (!this._dragProps) {
      this._dragProps = {
        lastX: 0,
        x: null,
        y: null
      };
    }
    this._dragProps.lastY = this._moveProps.y;

    this.__ngxDropdownService.hideAll();
  }

  moveRight(modifier = 1): void {
    this._moveProps.x += (modifier * this.moveStep) / this._moveProps.zoom;
    if (!this._dragProps) {
      this._dragProps = {
        lastY: 0,
        x: null,
        y: null
      };
    }
    this._dragProps.lastX = this._moveProps.x;

    this.__ngxDropdownService.hideAll();
  }

  moveLeft(modifier = 1): void {
    this._moveProps.x -= (modifier * this.moveStep) / this._moveProps.zoom;
    if (!this._dragProps) {
      this._dragProps = {
        lastY: 0,
        x: null,
        y: null
      };
    }
    this._dragProps.lastX = this._moveProps.x;

    this.__ngxDropdownService.hideAll();
  }

  historyUndo(): void {
    if (!this._isEditNode || this._historyProps.currentStep === 0) {
      return;
    }

    let step = this._historyProps.currentStep - 1;

    if (step < 0) {
      step = 0;
    }

    this._historyProps.currentStep = step;

    this._data = instanceToInstance(this._historyProps.steps[this._historyProps.currentStep]);

    this.__ngxDropdownService.hideAll();
  }

  historyRedo(): void {
    if (!this._isEditNode || this._historyProps.currentStep === this._historyProps.steps.length - 1) {
      return;
    }

    let step = this._historyProps.currentStep + 1;

    if (step > this._historyProps.steps.length - 1) {
      step = this._historyProps.steps.length - 1;
    }

    this._historyProps.currentStep = step;

    this._data = instanceToInstance(this._historyProps.steps[this._historyProps.currentStep]);

    this.__ngxDropdownService.hideAll();
  }

  protected _treeMarkSelected(value: boolean): void {
    this._tree.forEach((node) => (node.item.item.selected = value));
    this._treeAllSelected = value;
    this._treeSomeSelected = false;
  }

  treeMarkAllselected(): void {
    this._treeAllSelected = this._tree.every((node) => node.item.item.selected);
    this._treeSomeSelected = this._tree.filter((t) => t.item.item.selected).length > 0 && !this._treeAllSelected;
  }

  private __treeMap(tree: IBsTreeItem[]): BsTreeItem[] {
    const expansionStorage = sessionStorage.getItem('business-storage-expansion');
    let expansion: { [treeId: string | number]: boolean } = {};

    if (expansionStorage) {
      expansion = JSON.parse(expansionStorage);
    }

    const levelList: BsTreeItem[] = [];
    for (const item of tree) {
      const resetExpansion = !!item.reset_expansion;

      const treeItem = new BsTreeItem();
      treeItem.id = item.id;
      treeItem.parent_id = item.parent_id;
      treeItem.depth = item.depth;
      treeItem.expanded = resetExpansion ? !!item.expanded : expansion[item.id] || false;
      treeItem.name = item.name;
      treeItem.type = this.treeItemsType;
      treeItem.item = item;
      levelList.push(treeItem);
    }

    return levelList;
  }

  getChildren<T = any>(nodeOrTreeId: BsTreeItemNode | string | number): BsTreeItemNode<T>[] {
    if (nodeOrTreeId instanceof BsTreeItemNode) {
      nodeOrTreeId = nodeOrTreeId.treeId;
    }
    return this._treeStorage[nodeOrTreeId] || [];
  }

  appendChildren(parentId: string | number, expanded: boolean): void {
    const parentIndex = this._treeRender.findIndex((node) => node.treeId === parentId);
    let items = [...this._treeRender];

    if (expanded) {
      items = items.filter((item) => item.parentTreeId !== parentId);
      this._treeStorage[parentId] && items.splice(parentIndex + 1, 0, ...this._treeStorage[parentId]);
    } else {
      items = items.filter((item) => item.parentTreeId !== parentId);
    }

    this._treeRender = [...items];
    this.__cd.detectChanges();
  }

  private __treeHierarchy(source: BsTreeItem[]): {
    available: BsTreeItemNode[];
    render: BsTreeItemNode[];
    storage: { [treeId: string | number]: BsTreeItemNode[] };
  } {
    const available: BsTreeItemNode[] = [];
    const render: BsTreeItemNode[] = [];
    const storage: { [treeId: string | number]: BsTreeItemNode[] } = {};

    for (const treeItem of source) {
      const node: BsTreeItemNode = new BsTreeItemNode();
      node.treeId = treeItem.id;
      node.parentTreeId = treeItem.parent_id || 0;
      node.depth = treeItem.depth || 0;
      node.expanded = !!treeItem.expanded;
      node.reset_expansion = !!treeItem.reset_expansion;
      node.item = treeItem;
      node.has_children = treeItem.item.item.has_children;

      available.push(node);

      if (node.depth === 0) {
        render.push(node);
      } else {
        if (!storage[node.parentTreeId]) {
          storage[node.parentTreeId] = [];
        }

        storage[node.parentTreeId].push(node);
      }
    }

    return { available, render, storage };
  }

  protected _nodeAccountsListViewToggle(): void {
    setTimeout(() => {
      this.__refreshTargets();
      this.__cd.detectChanges();
    }, 10);
  }
}
