import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, HostBinding, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { ClientsOutletLayoutComponent } from '@layouts/clients-outlet-layout/clients-outlet-layout.component';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { BusinessStructureSharedModule } from '@shared/business-structure/business-structure-shared.module';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';
import { NgxDropdownModule } from '@shared/ngxdropdown/ngxdropdown.module';
import { OctraUISharedModule } from '@shared/octra-ui/octra-ui-shared.module';
import { PlatformDropdownComponent } from '@shared/platforms/components/platform-dropdown/platform-dropdown.component';
import { PlatformsService } from '@shared/platforms/services/platforms.service';
import { User } from '@shared/users-module/models/user.model';
import { UserService } from '@shared/users-module/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';

@Component({
  standalone: true,
  selector: 'client-layout-header',
  templateUrl: './client-layout-header.component.html',
  styleUrl: './client-layout-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    BusinessStructureSharedModule,
    RouterModule,
    OctraUISharedModule,
    NgxDropdownModule,
    MatTooltipModule,
    PlatformDropdownComponent
  ]
})
export class ClientLayoutHeaderComponent {
  private __router = inject(Router);
  private __route = inject(ActivatedRoute);
  private __destroyRef = inject(DestroyRef);
  private __authFacade = inject(AuthFacade);
  private __userService = inject(UserService);
  private __toastService = inject(ToastrService);
  private __loaderService = inject(GlobalLoaderService);
  private __platformsService = inject(PlatformsService);

  layout = inject(ClientsOutletLayoutComponent);
  APP_ROUTES = APP_ROUTES;
  user: User;
  $displayDropdowns = computed<boolean>(() => !this.__platformsService.$config().hideDropdowns);
  $displayPlatformsDropdown = computed<boolean>(() => !this.__platformsService.$config().hidePlatformsDropdown);
  $dateNow = signal(new Date());
  $pageWithClientSlug = toSignal(
    this.__route.paramMap.pipe(
      takeUntilDestroyed(),
      map((params) => params?.get('clientSlug'))
    )
  );

  constructor() {
    this.__authFacade.user$.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((user) => (this.user = user));
  }

  @HostBinding('class') get cssClass(): string[] {
    return [
      'd-flex',
      'align-items-center',
      'justify-content-between',
      'bg-color-white',
      'wpp-shadow-grey-m',
      'ps-12',
      'pe-12',
      'h-64',
      'h-min-64'
    ];
  }

  get isIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  v1_stopImpersonation(): void {
    this.__loaderService.create('user-impersonate');
    this.__userService.v1_stopUserImpersonation().subscribe({
      next: () => {
        this.__loaderService.dismiss('user-impersonate');
        window.location.reload();
      },
      error: (error: HttpErrorResponse) => {
        this.__loaderService.dismiss('user-impersonate');
        this.__toastService.error(error.error.message || 'Error while stopping impersonating user');
      }
    });
  }

  logout(): void {
    this.__authFacade.logout();
    this.__router.navigate(APP_ROUTES.login());
  }
}
