import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LevelsStringSplitPipe } from '@core/pipes/pipes/levels-string-split.pipe';
import { BestPracticesTemplateDialogInputsComponent } from '@shared/best-practice/components/best-practices-template-dialog/best-practices-template-dialog-inputs/best-practices-template-dialog-inputs.component';
import { BestPracticesTemplateDialogComponent } from '@shared/best-practice/components/best-practices-template-dialog/best-practices-template-dialog.component';
import { BestPracticeService } from '@shared/best-practice/services/best-practice.service';
import { DebouncedClickDirective } from '@shared/common/directives/debounced-click.directive';
import { GovernanceSharedModule } from '@shared/governance/governance.module';
import { PopupsSharedModule } from '@shared/popups/popups-shared.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    GovernanceSharedModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    PopupsSharedModule,
    MatIconModule,
    MatTooltipModule,
    LevelsStringSplitPipe,
    DebouncedClickDirective
  ],
  exports: [BestPracticesTemplateDialogInputsComponent],
  declarations: [BestPracticesTemplateDialogComponent, BestPracticesTemplateDialogInputsComponent]
})
export class BestPracticeSharedModule {
  static forRoot(): ModuleWithProviders<BestPracticeSharedModule> {
    return {
      ngModule: BestPracticeSharedModule,
      providers: [BestPracticeService, FormGroupDirective]
    };
  }
}
